import React from 'react';
import './styles/brands.css';

const Brands = () => {
  return (
    <section className="brands">
      <div className="brands-content">
        <span className="section-subtitle">Vores Sortiment</span>
        <h2>Premium Spirits & Services</h2>
        <div className="separator">
          <span style={{background: 'linear-gradient(to right, rgba(139, 0, 0, 0.2), rgba(139, 0, 0, 0.05))'}}></span>
          <i className="wine-icon" style={{color: '#8b0000', opacity: '0.8'}}>♦</i>
          <span style={{background: 'linear-gradient(to right, rgba(139, 0, 0, 0.2), rgba(139, 0, 0, 0.05))'}}></span>
        </div>
        
        <div className="brands-grid">
          <div className="brand-box">
            <div className="brand-content">
              <h3>Premium Spirits</h3>
              <ul className="brand-features">
                <li>Private Label Gin</li>
                <li>Private Label Rom</li>
              </ul>
            </div>
          </div>

          <div className="brand-box">
            <div className="brand-content">
              <h3>Services</h3>
              <ul className="brand-features">
                <li>Firmagaver</li>
                <li>Receptionsgaver</li>
                <li>Påskegaver</li>
                <li>Julegaver</li>
                <li>Sommergaver</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Brands;
