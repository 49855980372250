import React, { useState, useEffect } from 'react';
import './styles/header.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.querySelector(`.${sectionId}`);
    if (section) {
      section.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
      setIsMenuOpen(false);
    }
  };

  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <div className="header-container">
        <div className="logo">
          <img 
            src="/media/PL-logo.png" 
            alt="PL Vinimport Logo" 
            onClick={() => scrollToSection('hero')}
            style={{cursor: 'pointer'}}
          />
        </div>
        
        <nav className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
          <ul>
            <li><a href="#" onClick={(e) => { e.preventDefault(); scrollToSection('hero'); }}>Hjem</a></li>
            <li><a href="#" onClick={(e) => { e.preventDefault(); scrollToSection('om-section'); }}>Om os</a></li>
            <li><a href="#" onClick={(e) => { e.preventDefault(); scrollToSection('voresrom'); }}>Vores Rom</a></li>
            <li><a href="#" onClick={(e) => { e.preventDefault(); scrollToSection('profilprodukter'); }}>Profilprodukter</a></li>
            <li><a href="#" onClick={(e) => { e.preventDefault(); scrollToSection('kontakt'); }}>Kontakt</a></li>
          </ul>
        </nav>

        <button 
          className={`menu-toggle ${isMenuOpen ? 'active' : ''}`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label="Toggle menu"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </header>
  );
};

export default Header;
