import React from 'react';
import './styles/voresrom.css';

const VoresRom = () => {
  return (
    <section className="voresrom">
      <div className="voresrom-container">
        <div className="rom-billede">
          <img src="/media/udvalg/rom/Anthonsen_Cara_Dorada-rom.png" alt="Anthonsen Cara Dorada Rom" />
        </div>
        <div className="rom-content">
          <span className="section-subtitle">Vores Rom</span>
          <div className="title-logo">
            <img src="/media/logos/Logo_Anthonsen_CD.png" alt="Anthonsen Logo" />
          </div>
          <div className="separator">
            <span style={{background: 'linear-gradient(to right, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))'}}></span>
            <i className="wine-icon" style={{color: '#fff', opacity: '0.8'}}>♦</i>
            <span style={{background: 'linear-gradient(to right, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1))'}}></span>
          </div>
          <div className="rom-text">
            <p>
              Anthonsen Cara Dorada er en exceptionel limited edition rom, der 
              forener traditionelt håndværk med moderne elegance. Med sin gyldne 
              farve og komplekse smagsprofil repræsenterer den essensen af 
              premium rom.
            </p>
            <p>
              Denne unikke rom byder på en harmonisk balance mellem sødme og 
              dybde, med noter af vanilje, karamel og eksotiske krydderier. 
              Den lange eftersmag afslører lag af kompleksitet, der gør hver 
              sip til en opdagelsesrejse.
            </p>
          </div>
          <div className="rom-detaljer">
            <div className="detalje">
              <span>40% Vol.</span>
              <p>Alkohol</p>
            </div>
            <div className="detalje">
              <span>50 cl</span>
              <p>Indhold</p>
            </div>
            <div className="detalje">
              <span>Limited Edition</span>
              <p>Tilgængelighed</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VoresRom;
