import React, { useState } from 'react';
import './styles/kontakt.css';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

// Firebase konfiguration
const firebaseConfig = {
  apiKey: "AIzaSyB3HpII8xKHlOQ8JIE6U3_xtAn2AGSur08",
  authDomain: "parla-plvin.firebaseapp.com",
  projectId: "parla-plvin",
  storageBucket: "parla-plvin.firebasestorage.app",
  messagingSenderId: "350305805549",
  appId: "1:350305805549:web:22c4ddef8573eede2e98e4"
};

// Initialiser Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Kontakt = () => {
  const [formData, setFormData] = useState({
    navn: '',
    email: '',
    emne: '',
    besked: ''
  });
  const [submitStatus, setSubmitStatus] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('sending');

    const emailData = {
      to: ['ba@plvinimport.dk'],
      from: 'infoparlabranding@gmail.com',
      message: {
        subject: `Ny henvendelse fra ${formData.navn}: ${formData.emne}`,
        text: `
          Navn: ${formData.navn}
          Email: ${formData.email}
          Emne: ${formData.emne}
          
          Besked:
          ${formData.besked}
        `.trim(),
        html: `
          <h2>Ny henvendelse fra hjemmesiden</h2>
          <p><strong>Navn:</strong> ${formData.navn}</p>
          <p><strong>Email:</strong> ${formData.email}</p>
          <p><strong>Emne:</strong> ${formData.emne}</p>
          <p><strong>Besked:</strong></p>
          <p>${formData.besked.replace(/\n/g, '<br>')}</p>
        `.trim()
      }
    };

    try {
      console.log('Forsøger at sende email med data:', emailData);
      const docRef = await addDoc(collection(db, 'mail'), emailData);
      console.log('Email dokument oprettet med ID:', docRef.id);
      console.log('Email sendt succesfuldt til:', emailData.to);

      setSubmitStatus('success');
      setFormData({ navn: '', email: '', emne: '', besked: '' });
      
      setTimeout(() => {
        setSubmitStatus('');
      }, 5000);

    } catch (error) {
      console.error('Detaljeret fejl ved afsendelse:', error);
      console.error('Fejl stack:', error.stack);
      console.error('Fejl kode:', error.code);
      setSubmitStatus('error');
      
      setTimeout(() => {
        setSubmitStatus('');
      }, 5000);
    }
  };

  return (
    <section className="kontakt">
      <div className="kontakt-content">
        <span className="section-subtitle">Kontakt Os</span>
        <h2>Tøv ikke med at kontakte os</h2>
        <div className="separator">
          <span style={{background: 'linear-gradient(to right, rgba(139, 0, 0, 0.2), rgba(139, 0, 0, 0.05))'}}></span>
          <i className="wine-icon" style={{color: '#8b0000', opacity: '0.8'}}>♦</i>
          <span style={{background: 'linear-gradient(to right, rgba(139, 0, 0, 0.2), rgba(139, 0, 0, 0.05))'}}></span>
        </div>
        <p className="section-intro">Telefonen er åben alle hverdage</p>
        
        <div className="kontakt-container">
          <div className="kontakt-info">
            <div className="info-item">
              <i className="fas fa-map-marker-alt"></i>
              <div>
                <h3>Adresse</h3>
                <p>Marøgelhøj 9, Lystrup 8250</p>
              </div>
            </div>
            
            <div className="info-item">
              <i className="fas fa-phone"></i>
              <div>
                <h3>Telefon</h3>
                <p>+45 86 15 53 00</p>
              </div>
            </div>
            
            <div className="info-item">
              <i className="fas fa-envelope"></i>
              <div>
                <h3>Email</h3>
                <p>ba@plvinimport.dk</p>
              </div>
            </div>
          </div>
          
          <form className="kontakt-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <input 
                type="text" 
                name="navn"
                placeholder="Dit navn" 
                required 
                value={formData.navn}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <input 
                type="email" 
                name="email"
                placeholder="Din email" 
                required 
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <input 
                type="text" 
                name="emne"
                placeholder="Emne" 
                required 
                value={formData.emne}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <textarea 
                name="besked"
                placeholder="Din besked" 
                required
                value={formData.besked}
                onChange={handleChange}
              ></textarea>
            </div>
            <button 
              type="submit" 
              className={`submit-btn ${submitStatus}`}
              disabled={submitStatus === 'sending'}
            >
              {submitStatus === 'sending' ? 'Sender...' : 'Send Besked'}
            </button>
            {submitStatus === 'success' && (
              <div className="form-status success">
                Tak for din besked! Vi vender tilbage hurtigst muligt.
              </div>
            )}
            {submitStatus === 'error' && (
              <div className="form-status error">
                Der opstod en fejl. Prøv venligst igen senere.
              </div>
            )}
          </form>
        </div>
      </div>
    </section>
  );
};

export default Kontakt;
