import React from 'react';
import './App.css';
import Header from './components/header';
import Hero from './components/hero';
import Showcase from './components/showcase';
import Om from './components/om';
import VoresRom from './components/voresrom';
import ProfilProdukter from './components/profilprodukter';
import Brands from './components/brands';
import Footer from './components/footer';
import Kontakt from './components/kontakt';

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Showcase />
      <Om />
      <VoresRom />
      <ProfilProdukter />
      <Brands />
      <Kontakt />
      <Footer />
    </div>
  );
}

export default App;
