import React from 'react';
import './styles/hero.css';

const Hero = () => {
  const scrollToContent = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    });
  };

  const scrollToSection = (sectionId) => {
    const section = document.querySelector(`.${sectionId}`);
    if (section) {
      section.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  return (
    <section className="hero">
      <div className="hero-overlay"></div>
      <div className="hero-content">
        <span className="hero-subtitle">Velkommen til</span>
        <h1>PL Vinimport</h1>
        <div className="hero-separator">
          <span style={{background: 'linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.05))'}}></span>
          <i className="wine-icon" style={{color: '#ffffff', opacity: '0.8'}}>♦</i>
          <span style={{background: 'linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.05))'}}></span>
        </div>
        <p>
          Med passion og ekspertise udvælger vi de fineste vine fra 
          seriøse og respekterede vingårde. Hvert vinhus er håndplukket 
          med omhu for at bringe de ypperste vine til det danske marked.
        </p>
        <div className="hero-cta-container">
          <button 
            className="cta-button"
            onClick={() => scrollToSection('profilprodukter')}
          >
            Profilprodukter
          </button>
          <button 
            className="cta-button"
            onClick={() => scrollToSection('voresrom')}
          >
            Vores Rom
          </button>
        </div>
      </div>
      <div className="scroll-indicator" onClick={scrollToContent}>
        <span className="scroll-text">Scroll ned</span>
        <div className="scroll-arrow-down"></div>
      </div>
    </section>
  );
};

export default Hero;
