import React, { useEffect, useRef } from 'react';
import './styles/showcase.css';

const Showcase = () => {
  const sliderRef = useRef(null);
  
  const logos = [
    'SELLO_BTT_CIRCULO_UNICO-logo.webp',
    'Quinta-do-Estanho_logo.webp',
    'logo-rhonea.webp',
    'logo-beige-new2-1-logo.webp',
    'LOGO_LE_BOURDIEU-logo.webp',
    'HB_logo-VERT-et-OR.png',
    'Bloc-marque-logo.jpg',
    'Logo_San_Simone.png',
    'Logo_Anthonsen_CD.png',
    'Charles Pasteur Logo.PNG',
    'rhone-hus-logo.png'
  ];

  useEffect(() => {
    const slider = sliderRef.current;
    let position = 0;
    
    const scroll = () => {
      position -= 1;
      slider.style.transform = `translateX(${position}px)`;
      
      // Når første sæt af logoer er scrollet forbi, reset position
      if (position <= -(slider.firstElementChild.offsetWidth * logos.length)) {
        position = 0;
      }
      
      requestAnimationFrame(scroll);
    };

    const animation = requestAnimationFrame(scroll);
    
    return () => cancelAnimationFrame(animation);
  }, [logos.length]);

  return (
    <section className="showcase">
      <div className="slider-container">
        <div className="slider-track" ref={sliderRef}>
          {/* Første sæt af logoer */}
          {logos.map((logo, index) => (
            <div key={`logo-1-${index}`} className="logo-item">
              <img 
                src={`/media/logos/${logo}`} 
                alt={`Vinproducent ${logo.replace(/\.(webp|jpg|png)$/, '').replace(/-logo$/, '')}`} 
              />
            </div>
          ))}
          {/* Dubleret sæt for uendelig scroll */}
          {logos.map((logo, index) => (
            <div key={`logo-2-${index}`} className="logo-item">
              <img 
                src={`/media/logos/${logo}`} 
                alt={`Vinproducent ${logo.replace(/\.(webp|jpg|png)$/, '').replace(/-logo$/, '')}`} 
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Showcase;
