import React from 'react';
import './styles/footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-grid">
          <div className="footer-section brand-section">
            <img src="/media/PL-logo.png" alt="PL Vinimport Logo" className="footer-logo" />
            <p>Kvalitetsvine fra egen import siden 1990. Vores passion for vin afspejles i hver eneste flaske vi udvælger til vores kunder.</p>
            <p className="cvr">CVR: 37765511</p>
            <div className="social-links">
              <a href="#" aria-label="Facebook">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#" aria-label="Instagram">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#" aria-label="LinkedIn">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
          
          <div className="footer-section">
            <h4>Kontakt</h4>
            <ul>
              <li>
                <i className="fas fa-map-marker-alt"></i>
                <span>Marøgelhøj 9, Lystrup 8250</span>
              </li>
              <li>
                <i className="fas fa-phone"></i>
                <span>+45 86 15 53 00</span>
              </li>
              <li>
                <i className="fas fa-envelope"></i>
                <span>ba@plvinimport.dk</span>
              </li>
            </ul>
          </div>
          
          <div className="footer-section">
            <h4>Åbningstider</h4>
            <ul className="opening-hours">
              <li><span>Mandag - Fredag</span><span>09:00 - 14:00</span></li>
              <li><span>Lørdag - Søndag</span><span>Lukket</span></li>
            </ul>
          </div>
        </div>
        
        <div className="footer-bottom">
          <p>&copy; 2025 Skabt af <a href="https://www.parlabranding.dk" target="_blank" rel="noopener noreferrer" style={{color: '#8b0000', textDecoration: 'none'}}>Parla Branding</a></p>
          <a 
            href="https://www.findsmiley.dk/755643" 
            target="_blank" 
            rel="noopener noreferrer"
            className="smiley-link"
          >
            <img 
              src="/media/fødevarestyrelsen/fødevare-smiley.png" 
              alt="Fødevarestyrelsens Smiley-ordning" 
              className="smiley-icon"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
