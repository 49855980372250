import React from 'react';
import './styles/om.css';

const Om = () => {
  return (
    <section className="om-section" id="om-os">
      <div className="om-container">
        <div className="om-content">
          <span className="om-subtitle">Vores Historie</span>
          <h2>Passion for Vin<br />Siden 1990</h2>
          <div className="om-separator">
            <span style={{background: 'linear-gradient(to right, rgba(139, 0, 0, 0.2), rgba(139, 0, 0, 0.05))'}}></span>
            <i className="wine-icon" style={{color: '#8b0000', opacity: '0.8'}}>♦</i>
            <span style={{background: 'linear-gradient(to right, rgba(139, 0, 0, 0.2), rgba(139, 0, 0, 0.05))'}}></span>
          </div>
          <div className="om-text">
            <p>
              I mere end 30 år har PL Vinimport været dedikeret til at bringe exceptionelle 
              portvine, vine samt spiritus til det nordiske marked. Vores vision er baseret 
              på passion for kvalitets vine, godt selskab og ærlighed samt direkte import, 
              så i som kunder undgår dyre mellemled. Vi handler kun med B2B kunder samt 
              virksomheder der ønsker at give deres medarbejder en oplevelse udover det 
              sædvanlige. Private kunder henviser vi med glæde til vores samarbejdskunder 
              i hele landet.
            </p>
            <p>
              Vi samarbejder direkte med håndplukkede producenter, der deler vores værdier 
              om kvalitet, loyalitet, tradition og bæredygtighed. Hver produkt i vores 
              sortiment er nøje udvalgt gennem personlige smag og langvarige relationer 
              med fantastiske folk fra hele verden.
            </p>
          </div>
          <div className="om-features">
            <div className="feature">
              <h3>Loyalitet</h3>
              <p>Langvarige relationer med kunder og producenter</p>
            </div>
            <div className="feature">
              <h3>Kvalitet</h3>
              <p>Håndplukkede vine fra prestigefyldte vingårde</p>
            </div>
            <div className="feature">
              <h3>Tradition</h3>
              <p>Over 30 års erfaring med import af kvalitetsvine</p>
            </div>
          </div>
        </div>
        <div className="om-image">
          <img src="/media/brian.png" alt="PL Vinimport" />
        </div>
      </div>
    </section>
  );
};

export default Om;
