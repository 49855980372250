import React from 'react';
import './styles/profilprodukter.css';

const ProfilProdukter = () => {
  const produkter = [
    // Første række
    {
      navn: "San Simone",
      type: "Sauvignon Blanc",
      beskrivelse: "Aromatisk hvidvin med frisk citrus og eksotiske frugter",
      billede: "/media/udvalg/San_Simone_Sauv.png"
    },
    {
      navn: "Tagua Chardonnay",
      type: "Valle Central, Chile",
      beskrivelse: "Frisk og velbalanceret hvidvin med noter af tropisk frugt og citrus",
      billede: "/media/udvalg/Tagua_Chardonnay.png"
    },
    {
      navn: "Vouvray La Forcine",
      type: "Loire, Frankrig",
      beskrivelse: "Elegant og mineralsk hvidvin med fine noter af æble og honning",
      billede: "/media/udvalg/Vouvray_La_Forcine.png"
    },
    {
      navn: "Charles P Sauvignon Blanc",
      type: "Sauvignon Blanc",
      beskrivelse: "Blød og fadlagret hvidvin med noter af friske bær",
      billede: "/media/udvalg/Charles_P_Sauv.Blanc.png"
    },
    // Anden række
    {
      navn: "Charles P Grenache",
      type: "Grenache",
      beskrivelse: "Elegant og frugtig rødvin med bløde tanniner og noter af modne bær",
      billede: "/media/udvalg/Charles_P_Grenache.png"
    },
    {
      navn: "Tagua",
      type: "Cabernet Sauvignon",
      beskrivelse: "Fyldig chilensk rødvin med mørke bær og bløde tanniner",
      billede: "/media/udvalg/Tagua_Cabernet_Sauv.png"
    },
    {
      navn: "Château Le Bourdieu",
      type: "Médoc Cru Bourgeois",
      beskrivelse: "Klassisk Bordeaux med flot struktur og moden frugt",
      billede: "/media/udvalg/Medoc.png"
    },
    {
      navn: "Charles P Syrah",
      type: "Syrah",
      beskrivelse: "Halvtør klassisk fransk rosé med noter af frisk frugt",
      billede: "/media/udvalg/Charles_P_Syrah.png"
    },
    // Tredje række
    {
      navn: "Quinta do Estanho 10 Years",
      type: "Tawny Port",
      beskrivelse: "Kompleks og elegant portvin med 10 års fadlagring",
      billede: "/media/udvalg/Quinta_Do_Estanho_10.png"
    },
    {
      navn: "Quinta do Estanho 20 Years",
      type: "Tawny Port",
      beskrivelse: "Intens og raffineret portvin med 20 års fadlagring",
      billede: "/media/udvalg/Quinta_Do_Estanho_20.png"
    },
    {
      navn: "Quinta do Estanho 30 Years",
      type: "Tawny Port",
      beskrivelse: "Exceptionel portvin med 30 års fadlagring og stor kompleksitet",
      billede: "/media/udvalg/Quinta_Do_Estanho_30.png"
    },
    {
      navn: "Quinta do Estanho TB",
      type: "Tawny Port",
      beskrivelse: "Original trææske med 5 forskellige portvine. Oplagt smagsæske",
      billede: "/media/udvalg/Quinta_Do_Estanho_TB.png"
    },
    // Fjerde række
    {
      navn: "Huber Bleger Crémant",
      type: "Crémant d'Alsace",
      beskrivelse: "Forfriskende mousserende vin med fine bobler og delikat frugtighed",
      billede: "/media/udvalg/Huber_Bleger_Cremant.png"
    },
    {
      navn: "Huber Bleger Rosé",
      type: "Crémant d'Alsace Rosé",
      beskrivelse: "Elegant og frisk rosé crémant med delikate bærnoter og fin mineralitet",
      billede: "/media/udvalg/Huber_Bleger_Rose.png"
    },
    {
      navn: "Huber Bleger Crémant Magnum",
      type: "Crémant d'Alsace Magnum",
      beskrivelse: "Eksklusiv magnum udgave af den populære crémant med ekstra elegance",
      billede: "/media/udvalg/Huber_Bleger_Cremant_Magnum.png"
    },
    {
      navn: "Muscatine Sans Alcool",
      type: "Alkoholfri Mousserende",
      beskrivelse: "Forfriskende alkoholfri mousserende med fine bobler og delikat sødme",
      billede: "/media/udvalg/Muscatine_SANS_ALCOOL_1698748579.png"
    },
    // Femte række (ny)
    {
      navn: "Côtes du Rhône",
      type: "Rhône, Frankrig",
      beskrivelse: "Klassisk Côtes du Rhône med fylde og elegante krydderier",
      billede: "/media/udvalg/CDR-SM.png"
    },
    {
      navn: "Couchant",
      type: "Rhône, Frankrig",
      beskrivelse: "Elegant rødvin med flot balance og bløde tanniner",
      billede: "/media/udvalg/Couchant-SM.png"
    },
    {
      navn: "Domaine Gigondas",
      type: "Gigondas, Rhône",
      beskrivelse: "Kraftfuld og kompleks vin fra det prestigefyldte Gigondas område",
      billede: "/media/udvalg/Domaine-gigondas.png"
    },
    {
      navn: "Vieilles Vignes Blanc",
      type: "Rhône, Frankrig",
      beskrivelse: "Elegant hvidvin fra gamle vinstokke med fin mineralitet",
      billede: "/media/udvalg/VieillesVignes_Blanc-SM.png"
    }
  ];

  return (
    <section className="profilprodukter">
      <div className="profilprodukter-content">
        <span className="section-subtitle">Udvalgte produkter</span>
        <h2>Profilprodukter</h2>
        <div className="separator">
          <span style={{background: 'linear-gradient(to right, rgba(139, 0, 0, 0.2), rgba(139, 0, 0, 0.05))'}}></span>
          <i className="wine-icon" style={{color: '#8b0000', opacity: '0.8'}}>♦</i>
          <span style={{background: 'linear-gradient(to right, rgba(139, 0, 0, 0.2), rgba(139, 0, 0, 0.05))'}}></span>
        </div>
        <p className="section-intro">
          Her præsenterer vi et udvalg af vores mest efterspurgte vine. 
          PL Vinimport tilbyder et omfattende sortiment med flere hundrede 
          forskellige kvalitetsvine fra nøje udvalgte producenter verden over. 
          Kontakt os for at høre mere om vores komplette udvalg.
        </p>
        
        <div className="produkter-grid">
          {produkter.map((produkt, index) => (
            <div key={index} className="produkt-kort">
              {produkt.navn === "Muscatine Sans Alcool" && (
                <span className="alkoholfri-badge">Alkoholfri</span>
              )}
              <div className="produkt-billede">
                <img src={produkt.billede} alt={produkt.navn} />
              </div>
              <div className="produkt-info">
                <h3>{produkt.navn}</h3>
                <span className="produkt-type">{produkt.type}</span>
                <p>{produkt.beskrivelse}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProfilProdukter;
